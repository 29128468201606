<template>
  <div class="card-file">
    <div
      v-if="!isEdit && !isRequest"
      class="card-file-body"
      role="button"
      tabindex="0"
      @click="handleOpenFileMain"
    >
      <p
        v-if="loading"
        class="loading-center"
      >
        loading....
      </p>
      <img
        v-else-if="image"
        :src="image_url"
        alt="logo file"
        class="image"
      >
      <div
        v-else
        class="card-file-body-content"
      >
        <img
          :src="fileImg"
          alt="logo file"
          width="60"
          height="60"
        >
        <span class="file-add-icon">
          <feather-icon
            icon="PlusCircleIcon"
            stroke="#fff"
            fill="#787878"
            size="22"
          />
        </span>
        <p class="cart-file-text">
          Upload Main Image
        </p>
      </div>
    </div>
    <img
      v-if="isRequest || isEdit"
      :src="image"
      alt="logo file"
      class="image"
    >
  </div>
</template>

<script>
export default {
  props: ['image', 'isRequest', 'isEdit'],
  data() {
    return {
      loading: false,
      image_url: null,
      fileImg: require('@/assets/images/logo/picture.svg'),
    }
  },

  watch: {
    image() {
      if (!this.is_Edit) {
        this.generateImageUrl(this.image)
      }
    },
  },
  // computed: {
  //   image_url() {
  //     if (this.isRequest || this.isEdit) {
  //       return this.image;
  //     }
  //   },
  // },
  methods: {
    generateImageUrl(imageFile) {
      this.loading = true
      this.image_url = URL.createObjectURL(imageFile)
      this.loading = false
    },

    handleOpenFileMain() {
      document.getElementById('main-file').click()
    },
  },
}
</script>

<style scoped lang="scss">
.loading-center {
  transform: translate(0px, 5rem);
  text-align: center;
}
.image {
  object-fit: contain;
  width: 180px;
  height: 150px;
  transform: translate(0px, 0.6rem);
}
.card-file-body {
  cursor: pointer;
  height: 100%;

  .card-file-body-content {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100%;

    .file-add-icon {
      position: absolute;
      top: 80px;
      left: 105px;
    }

    .cart-file-text {
      margin-top: 0.8rem;
      font-weight: 400;
      font-size: 1rem;
      color: #787878;
    }
  }
}
</style>
