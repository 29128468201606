<template>
  <div class="card-files">
    <div class="card-file-body">
      <div
        v-if="!isRequest && !isEdit"
        class="btn-upload-file"
        role="button"
        tabindex="0"
        @click="handleOpenFileMain"
      >
        <div class="card-file-body-content">
          <img
            :src="fileImg"
            alt="logo file"
            width="60"
            height="60"
          >
          <span class="file-add-icon">
            <feather-icon
              icon="PlusCircleIcon"
              stroke="#fff"
              fill="#787878"
              size="22"
            />
          </span>
          <p class="cart-file-text">
            Images of Product
            <span v-if="images_Url.length">({{ images_Url.length }})</span>
          </p>
        </div>
      </div>
      <div
        v-for="(item, index) in images_Url"
        :key="index"
      >
        <img
          :src="item"
          alt="logo file"
          class="image"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['images', 'isRequest', 'isEdit'],
  data() {
    return {
      images_Url: [],
      fileImg: require('@/assets/images/logo/picture.svg'),
    }
  },

  watch: {
    images() {
      if (!this.isEdit) {
        this.images_Url = []
        this.images.forEach(file => {
          this.generateImageUrl(file)
        })
      }
    },
  },

  beforeMount() {
    if (this.isRequest || this.isEdit) {
      this.images_Url = this.images
    }
  },

  methods: {
    generateImageUrl(file) {
      this.images_Url.push(URL.createObjectURL(file))
    },

    handleOpenFileMain() {
      document.getElementById('product-files').click()
    },
  },
}
</script>

<style scoped lang="scss">
.card-file-body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-right: 1rem;
  gap: 10px;
  .btn-upload-file {
    cursor: pointer;
    width: 180px;
    height: 160px;
  }

  .image {
    object-fit: contain;
    width: 180px;
    height: 150px;
    border-radius: 5px;
  }

  .card-file-body-content {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 180px;

    .file-add-icon {
      position: absolute;
      top: 80px;
      left: 105px;
    }

    .cart-file-text {
      margin-top: 0.8rem;
      font-weight: 400;
      font-size: 1rem;
      color: #787878;
    }
  }
}
</style>
