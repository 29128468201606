var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('loading'):_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-card',{staticClass:"card-add-catalog"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"text","disabled":_vm.is_Edit},model:{value:(_vm.form_data.name),callback:function ($$v) {_vm.$set(_vm.form_data, "name", $$v)},expression:"form_data.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.barcode'),"label-for":"barcode"}},[_c('validation-provider',{attrs:{"name":"Barcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number","disabled":_vm.is_Edit},model:{value:(_vm.form_data.barcode),callback:function ($$v) {_vm.$set(_vm.form_data, "barcode", $$v)},expression:"form_data.barcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.CountryOfManufacture'),"label-for":"CountryOfManufacture"}},[_c('validation-provider',{attrs:{"name":"CountryOfManufacture"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"text","disabled":_vm.is_Edit},model:{value:(_vm.form_data.made_in),callback:function ($$v) {_vm.$set(_vm.form_data, "made_in", $$v)},expression:"form_data.made_in"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.brand'),"label-for":"Brand"}},[_c('validation-provider',{attrs:{"name":"Brand","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.brand_options,"label":"position","state":errors.length > 0 ? false : null,"disabled":_vm.is_Edit},model:{value:(_vm.form_data.brand_id),callback:function ($$v) {_vm.$set(_vm.form_data, "brand_id", $$v)},expression:"form_data.brand_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.Category'),"label-for":"Category"}},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.category_options,"label":"position","state":errors.length > 0 ? false : null,"disabled":_vm.is_Edit},model:{value:(_vm.form_data.category_id),callback:function ($$v) {_vm.$set(_vm.form_data, "category_id", $$v)},expression:"form_data.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.MainImage'),"label-for":"Main Image"}},[_c('validation-provider',{attrs:{"name":"Main Image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-main-image',{attrs:{"is-request":_vm.is_Edit,"is-edit":_vm.is_Edit,"image":_vm.image_main}}),(!_vm.is_Edit)?_c('b-form-file',{staticClass:"d-none",attrs:{"id":"main-file","state":errors.length > 0 ? false : null,"type":"file","hidden":"","accept":"image/*","length":"1"},on:{"change":_vm.handelChangeMainImage},model:{value:(_vm.image_main),callback:function ($$v) {_vm.image_main=$$v},expression:"image_main"}}):_vm._e(),(!_vm.image_main || _vm.image_main.length == 0)?_c('RecommendationUploadImage',{attrs:{"dimensions":{ width: 1200, height: 750 }}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"9"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.ImagesOfProduct'),"label-for":"Images of Product"}},[_c('input-product-images',{attrs:{"is-request":_vm.is_Edit,"is-edit":_vm.is_Edit,"images":_vm.form_data.images}}),(!_vm.is_Edit)?_c('b-form-file',{staticClass:"d-none",attrs:{"id":"product-files","type":"file","hidden":"","accept":"image/*","multiple":""},model:{value:(_vm.images),callback:function ($$v) {_vm.images=$$v},expression:"images"}}):_vm._e(),_c('RecommendationUploadImage',{attrs:{"dimensions":{ width: 1200, height: 750 }}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"switches-row"},[_c('b-form-group',{attrs:{"label":_vm.$t('g.is_rateable'),"label-for":"is_rateable"}},[_c('validation-provider',{attrs:{"name":"is_rateable"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","disabled":_vm.is_Edit},model:{value:(_vm.form_data.is_rateable),callback:function ($$v) {_vm.$set(_vm.form_data, "is_rateable", $$v)},expression:"form_data.is_rateable"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('g.is_buyable'),"label-for":"is_buyable"}},[_c('validation-provider',{attrs:{"name":"is_buyable"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","disabled":_vm.is_Edit},model:{value:(_vm.form_data.is_buyable),callback:function ($$v) {_vm.$set(_vm.form_data, "is_buyable", $$v)},expression:"form_data.is_buyable"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)]),(_vm.form_data.is_physical)?_c('b-col',[_c('div',{staticClass:"isPhsyica-form row"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.catalogs.width'),"label-for":"width"}},[_c('validation-provider',{attrs:{"name":"width"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_vm._v(_vm._s(_vm.$t("g.cm")))])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"disabled":_vm.is_Edit},model:{value:(_vm.form_data.dimensions.width),callback:function ($$v) {_vm.$set(_vm.form_data.dimensions, "width", $$v)},expression:"form_data.dimensions.width"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,2919113022)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.catalogs.height'),"label-for":"height"}},[_c('validation-provider',{attrs:{"name":"height"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_vm._v(_vm._s(_vm.$t("g.cm")))])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"disabled":_vm.is_Edit},model:{value:(_vm.form_data.dimensions.height),callback:function ($$v) {_vm.$set(_vm.form_data.dimensions, "height", $$v)},expression:"form_data.dimensions.height"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,3455553799)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.catalogs.length'),"label-for":"length"}},[_c('validation-provider',{attrs:{"name":"length"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_vm._v(_vm._s(_vm.$t("g.cm")))])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"disabled":_vm.is_Edit},model:{value:(_vm.form_data.dimensions.length),callback:function ($$v) {_vm.$set(_vm.form_data.dimensions, "length", $$v)},expression:"form_data.dimensions.length"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,2321489444)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.catalogs.weight'),"label-for":"weigth"}},[_c('validation-provider',{attrs:{"name":"weight"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_vm._v(_vm._s(_vm.$t("g.kg")))])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"disabled":_vm.is_Edit},model:{value:(_vm.form_data.dimensions.weight),callback:function ($$v) {_vm.$set(_vm.form_data.dimensions, "weight", $$v)},expression:"form_data.dimensions.weight"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,1593740602)})],1)],1)],1)]):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.description'),"label-for":"Brand"}},[_c('validation-provider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"rows":"4","disabled":_vm.is_Edit},model:{value:(_vm.form_data.description),callback:function ($$v) {_vm.$set(_vm.form_data, "description", $$v)},expression:"form_data.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-right"},[(!_vm.is_Edit)?_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary","type":"submit","disabled":_vm.isPending},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[(_vm.isPending)?_c('b-spinner',{attrs:{"small":"","label":"Small Spinner"}}):_c('span',[_c('feather-icon',{staticClass:"text-white",attrs:{"icon":"PlusIcon","size":"16"}}),_vm._v(" "+_vm._s(_vm.$t("g.catalogs.suggest_catalog")))],1)],1):_vm._e()],1)])],1),_c('overlay-component',{attrs:{"isLoading":_vm.isPending}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }