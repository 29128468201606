<template>
  <div>
    <loading v-if="isLoading" />

    <!-- form -->
    <validation-observer v-else ref="form">
      <b-form>
        <b-card class="card-add-catalog">
          <b-row>
            <!-- ** name -->
            <b-col md="6">
              <b-form-group :label="$t('g.name')" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.name"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    :disabled="is_Edit"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- **  name -->

            <!-- ** barcode -->
            <b-col md="6">
              <b-form-group :label="$t('g.barcode')" label-for="barcode">
                <validation-provider #default="{ errors }" name="Barcode">
                  <b-form-input
                    v-model="form_data.barcode"
                    :state="errors.length > 0 ? false : null"
                    type="number"
                    :disabled="is_Edit"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- **  barcode -->

            <!-- Country of Manufacture -->
            <b-col md="6">
              <b-form-group
                :label="$t('g.CountryOfManufacture')"
                label-for="CountryOfManufacture"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CountryOfManufacture"
                >
                  <!-- <b-form-select
                    v-model="form_data.made_in"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="country_options"
                    label="position"
                    :state="errors.length > 0 ? false : null"
                    :disabled="is_Edit"
                  /> -->
                  <b-form-input
                    v-model="form_data.made_in"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    :disabled="is_Edit"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Country of Manufacture -->
            <!-- Brand -->
            <b-col md="6">
              <b-form-group :label="$t('g.brand')" label-for="Brand">
                <validation-provider
                  #default="{ errors }"
                  name="Brand"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.brand_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="brand_options"
                    label="position"
                    :state="errors.length > 0 ? false : null"
                    :disabled="is_Edit"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Brand -->
            <!-- Category -->
            <b-col md="6">
              <b-form-group :label="$t('g.Category')" label-for="Category">
                <validation-provider
                  #default="{ errors }"
                  name="Category"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.category_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="category_options"
                    label="position"
                    :state="errors.length > 0 ? false : null"
                    :disabled="is_Edit"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Category -->

            <!-- Main Image -->
            <b-col md="6">
              <b-form-group :label="$t('g.MainImage')" label-for="Main Image">
                <validation-provider
                  #default="{ errors }"
                  name="Main Image"
                  rules="required"
                >
                  <!-- -->
                  <input-main-image
                    :is-request="is_Edit"
                    :is-edit="is_Edit"
                    :image="image_main"
                  />
                  <b-form-file
                    v-if="!is_Edit"
                    id="main-file"
                    v-model="image_main"
                    @change="handelChangeMainImage"
                    :state="errors.length > 0 ? false : null"
                    type="file"
                    hidden
                    class="d-none"
                    accept="image/*"
                    length="1"
                  />
                  <RecommendationUploadImage
                    v-if="!image_main || image_main.length == 0"
                    :dimensions="{ width: 1200, height: 750 }"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Main Image -->

            <!-- Images Of Product -->
            <b-col md="9">
              <b-form-group
                :label="$t('g.ImagesOfProduct')"
                label-for="Images of Product"
              >
                <!-- -->
                <input-product-images
                  :is-request="is_Edit"
                  :is-edit="is_Edit"
                  :images="form_data.images"
                />
                <b-form-file
                  v-if="!is_Edit"
                  id="product-files"
                  v-model="images"
                  type="file"
                  hidden
                  class="d-none"
                  accept="image/*"
                  multiple
                />
                <RecommendationUploadImage
                  :dimensions="{ width: 1200, height: 750 }"
                />
              </b-form-group>
            </b-col>
            <!-- ** Images Of Product -->
            <b-col md="12">
              <div class="switches-row">
                <b-form-group
                  :label="$t('g.is_rateable')"
                  label-for="is_rateable"
                >
                  <validation-provider #default="{ errors }" name="is_rateable">
                    <b-form-checkbox
                      v-model="form_data.is_rateable"
                      name="check-button"
                      switch
                      :disabled="is_Edit"
                    />

                    <small class="text-danger">{{
                      errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  :label="$t('g.is_buyable')"
                  label-for="is_buyable"
                >
                  <validation-provider #default="{ errors }" name="is_buyable">
                    <b-form-checkbox
                      v-model="form_data.is_buyable"
                      name="check-button"
                      switch
                      :disabled="is_Edit"
                    />

                    <small class="text-danger">{{
                      errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- <b-form-group
                  :label="$t('g.is_physical')"
                  label-for="is_physical"
                >
                  <validation-provider #default="{ errors }" name="is_physical">
                    <b-form-checkbox
                      v-model="form_data.is_physical"
                      name="check-button"
                      switch
                      :disabled="is_Edit"
                    />

                    <small class="text-danger">{{
                      errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group> -->
              </div>
            </b-col>
            <b-col v-if="form_data.is_physical">
              <div class="isPhsyica-form row">
                <b-col md="6">
                  <b-form-group
                    :label="$t('g.catalogs.width')"
                    label-for="width"
                  >
                    <validation-provider #default="{ errors }" name="width">
                      <b-input-group>
                        <template #append>
                          <b-input-group-text>{{
                            $t("g.cm")
                          }}</b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="form_data.dimensions.width"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                          :disabled="is_Edit"
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('g.catalogs.height')"
                    label-for="height"
                  >
                    <validation-provider #default="{ errors }" name="height">
                      <b-input-group>
                        <template #append>
                          <b-input-group-text>{{
                            $t("g.cm")
                          }}</b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="form_data.dimensions.height"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                          :disabled="is_Edit"
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('g.catalogs.length')"
                    label-for="length"
                  >
                    <validation-provider #default="{ errors }" name="length">
                      <b-input-group>
                        <template #append>
                          <b-input-group-text>{{
                            $t("g.cm")
                          }}</b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="form_data.dimensions.length"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                          :disabled="is_Edit"
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('g.catalogs.weight')"
                    label-for="weigth"
                  >
                    <validation-provider #default="{ errors }" name="weight">
                      <b-input-group>
                        <template #append>
                          <b-input-group-text>{{
                            $t("g.kg")
                          }}</b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="form_data.dimensions.weight"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                          :disabled="is_Edit"
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </div>
            </b-col>

            <!-- Description -->
            <b-col md="12">
              <b-form-group :label="$t('g.description')" label-for="Brand">
                <validation-provider #default="{ errors }" name="Description">
                  <b-form-textarea
                    v-model="form_data.description"
                    :state="errors.length > 0 ? false : null"
                    rows="4"
                    :disabled="is_Edit"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Description -->
          </b-row>
        </b-card>
        <b-row>
          <!-- Submit Button -->
          <b-col cols="12">
            <div class="text-right">
              <b-button
                v-if="!is_Edit"
                variant="primary"
                type="submit"
                :disabled="isPending"
                class="ml-1"
                @click.prevent="validationForm"
              >
                <b-spinner v-if="isPending" small label="Small Spinner" />
                <span v-else>
                  <feather-icon icon="PlusIcon" class="text-white" size="16" />
                  {{ $t("g.catalogs.suggest_catalog") }}</span
                >
              </b-button>
            </div>
          </b-col>
          <!-- ** Submit Button -->
        </b-row>
        <overlay-component :isLoading="isPending" />
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import OverlayComponent from "@/components/shared/OverlayComponent";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormSelect,
  BFormTextarea,
  BFormFile,
  BSpinner,
  BFormCheckbox,
  BInputGroup,
  BInputGroupText,
} from "bootstrap-vue";
import { required, confirmed, min } from "@validations";
import Loading from "@/components/shared/loading/loading.vue";
import inputProductImages from "@/components/catalogs/add/inputProductImages.vue";
import inputMainImage from "@/components/catalogs/add/inputMainImage.vue";
import RecommendationUploadImage from "@/components/shared/RecommendationUploadImage/index";

export default {
  components: {
    OverlayComponent,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    BCard,
    Loading,
    BFormTextarea,
    BFormFile,
    inputMainImage,
    inputProductImages,
    BSpinner,
    BFormCheckbox,
    BInputGroup,
    BInputGroupText,
    RecommendationUploadImage,
  },

  data() {
    return {
      form_data: {
        name: null,
        barcode: null,
        category_id: null,
        brand_id: null,
        description: null,
        made_in: null,
        images: [],
        is_available: true,
        is_physical: true,
        is_buyable: true,
        is_rateable: true,
        dimensions: {
          height: null,
          length: null,
          width: null,
          weight: null,
        },
      },
      image_main: null,
      images: [],
      category_options: [],
      brand_options: [],
      country_options: [],
      is_Edit: false,
      isLoading: true,
      currentId: null,
      subPermission: {},
      isPending: false,
    };
  },

  watch: {
    $route(to, from) {
      if (to.name === "AddCatalog") {
        this.handleWatchRoutePage();
      }
    },
    images() {
      this.images.forEach((file) => {
        this.images.shift();
        this.form_data.images.push(file);
      });
    },
  },

  created() {
    if (this.$route.params.id) {
      this.is_Edit = true;
      this.currentId = this.$route.params.id;
      this.getCatalogData(this.$route.params.id);
    }
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    getData() {
      this.$http
        .get("vendor/categories")
        .then((res) => {
          if (res.status === 200) {
            res.data.data.map((item) => {
              if (item.is_leaf) {
                this.category_options.push({
                  value: item.id,
                  text: item.name,
                });
              }
            });
          }
        })
        .catch((error) => {
          this.$helpers.handleError(error);
          this.pageStatus(false, true);
        });
      this.$http
        .get("vendor/brands")
        .then((res) => {
          if (res.status === 200) {
            res.data.data.map((item) => {
              this.brand_options.push({
                value: item.id,
                text: item.name,
              });
            });
          }
        })
        .catch((error) => {
          this.$helpers.handleError(error);
          this.pageStatus(false, true);
        });
      this.pageStatus(false, false);
      this.$http
        .get("web/countries")
        .then((res) => {
          if (res.status === 200) {
            res.data.data.map((item) => {
              this.country_options.push({
                value: item.id,
                text: item.name,
              });
            });
          }
        })
        .catch((error) => {
          this.$helpers.handleError(error);
          this.pageStatus(false, true);
        });
    },
    //  Clear Form Data
    clearForm() {
      for (const i in this.form_data) {
        if (i === "images") {
          this.form_data[i] = [];
        } else if (i === "dimensions") {
          this.form_data.dimensions.height = null;
          this.form_data.dimensions.length = null;
          this.form_data.dimensions.width = null;
          this.form_data.dimensions.weight = null;
        } else if (i === "is_available") {
          this.form_data.is_available = false;
        } else {
          this.form_data[i] = null;
        }
      }
    },

    pageStatus(loading, error) {
      this.isLoading = loading;
      this.isError = error;
    },

    // Update page data when it changes
    handleWatchRoutePage() {
      this.is_Edit = false;
      this.$refs.form.reset();
      this.clearForm();
    },

    getCatalogData(id) {
      this.$http
        .get(`vendor/catalogs/${id}`)
        .then((res) => {
          if (res.status === 200) {
            const { data } = res.data;
            this.form_data.name = data.name;
            this.form_data.barcode = data.barcode;
            this.form_data.description = data.description;
            this.form_data.made_in = data.made_in;
            this.form_data.category_id = data.category?.id;
            this.form_data.is_physical = data.is_physical;
            this.form_data.is_available = data.is_available;
            this.form_data.is_buyable = data.is_buyable;
            this.form_data.is_rateable = data.is_rateable;
            this.form_data.brand_id = data.brand.id;
            this.form_data.dimensions.height = data.dimensions?.height;
            this.form_data.dimensions.length = data.dimensions?.length;
            this.form_data.dimensions.width = data.dimensions?.width;
            this.form_data.dimensions.weight = data.dimensions?.weight;
            data.images.map((item) => {
              if (item.is_main) {
                this.image_main = item.path;
              } else {
                this.form_data.images.push(item.path);
              }
            });
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    //  Submit new catalogs
    submitNewAddition() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.isPending = true;
          const formData = this.collectData(false);
          this.$http
            .post("vendor/catalogs", formData)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                this.$helpers.makeToast(
                  "success",
                  res.data.message,
                  res.data.message
                );
                setTimeout(() => {
                  this.$router.push({ name: "CatalogList" });
                }, 1000);
                this.isPending = false;
              }
            })
            .catch((err) => {
              this.$helpers.handleError(err);

              this.isPending = false;
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
    collectData(isEdit) {
      const formData = new FormData();
      for (const item in this.form_data) {
        if (
          this.form_data[item] !== null &&
          item !== "dimensions" &&
          item !== "images"
        ) {
          const boolians = [
            "is_available",
            "is_physical",
            "is_rateable",
            "is_buyable",
          ];
          if (boolians.includes(item) && this.form_data.is_physical) {
            formData.append(item, Number(this.form_data[item]));
          } else {
            formData.append(item, this.form_data[item]);
          }
        } else if (item === "images") {
          const images = this.form_data.images.map((item) => {
            if (!item.hasOwnProperty("is_main")) {
              return {
                is_main: false,
                file: item,
              };
            }
          });
          const imagesFiles = [...images];
          if (this.image_main) {
            imagesFiles.push({ is_main: true, file: this.image_main });
          }
          if (imagesFiles.length > 0) {
            for (const item in imagesFiles) {
              if (imagesFiles[item].file?.lastModified) {
                formData.append(
                  `images[${item}][file]`,
                  imagesFiles[item].file
                );
                formData.append(
                  `images[${item}][is_main]`,
                  Number(imagesFiles[item].is_main)
                );
              }
            }
          }
        } else if (item === "dimensions") {
          if (this.form_data.is_physical) {
            for (const item in this.form_data.dimensions) {
              if (this.form_data.dimensions[item])
                formData.append(
                  `dimensions[${item}]`,
                  this.form_data.dimensions[item]
                );
            }
          }
        }
      }
      if (isEdit) {
        formData.append("lang", localStorage.getItem("lang"));
      }
      return formData;
    },

    handelDeleteRequest() {
      this.$router.push({ name: "CatalogList" });
    },
    handelChangeMainImage(input) {
      this.image_main = URL.createObjectURL(input);
    },
    validationForm() {
      this.submitNewAddition();
    },
  },
};
</script>

<style lang="scss">
@import "./style.scss";
</style>
